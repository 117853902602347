import React, { useEffect, useState } from 'react';
import authFetch from '../services/authFetch'; // Importe o authFetch para fazer a requisição
import '../styles/SuperuserPage.css'; // Importa o arquivo de estilo

function SuperuserPage() {
  const [usages, setUsages] = useState([]); // Estado para armazenar os dados de uso
  const [loading, setLoading] = useState(true); // Estado de carregamento
  const [error, setError] = useState(null); // Estado para armazenar erros
  const [startDate, setStartDate] = useState(''); // Filtro de data de início
  const [endDate, setEndDate] = useState(''); // Filtro de data de fim

  const [appliedStartDate, setAppliedStartDate] = useState('');
  const [appliedEndDate, setAppliedEndDate] = useState('');

  const [totalQueries, setTotalQueries] = useState(0);
  const [totalSpent, setTotalSpent] = useState(0);
  const [costTotal, setcostTotal] = useState(0);

  // Função para buscar os dados de consultas totais
  useEffect(() => {
    const fetchTotalUsages = async () => {
      try {
        let url = 'https://panel.zepesquisa.com.br/reports/usages-total/';

        // Adiciona parâmetros de filtro de data
        if (appliedStartDate) {
          url += `?start_date=${appliedStartDate}`;
        }
        if (appliedEndDate) {
          url += appliedStartDate ? `&end_date=${appliedEndDate}` : `?end_date=${appliedEndDate}`;
        }

        const response = await authFetch(url);
        if (!response.ok) throw new Error('Erro ao buscar dados de consultas totais');

        const data = await response.json();
        setTotalQueries(data.total_queries);
        setTotalSpent(data.total_spent);
        setcostTotal(data.cost_total);
      } catch (err) {
        setError(err.message);
      }
    };

    fetchTotalUsages();
  }, [appliedStartDate, appliedEndDate]); // Reexecuta sempre que as datas aplicadas mudarem

  // Função para buscar os dados da API com filtros aplicados
  useEffect(() => {
    const fetchUsages = async () => {
      try {
        let url = 'https://panel.zepesquisa.com.br/reports/usages/';

        // Adiciona parâmetros de filtro de data
        if (appliedStartDate) {
          url += `?start_date=${appliedStartDate}`;
        }
        if (appliedEndDate) {
          url += appliedStartDate ? `&end_date=${appliedEndDate}` : `?end_date=${appliedEndDate}`;
        }

        const response = await authFetch(url);
        if (!response.ok) throw new Error('Erro ao buscar dados de uso');

        const data = await response.json();
        setUsages(data); // Armazena os dados de uso no estado
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false); // Finaliza o estado de carregamento
      }
    };

    fetchUsages();
  }, [appliedStartDate, appliedEndDate]); // Reexecuta sempre que as datas aplicadas mudarem

  // Função para aplicar os filtros de data
  const applyFilters = () => {
    setAppliedStartDate(startDate);
    setAppliedEndDate(endDate);
  };

  // Função para limpar os filtros
  const clearFilters = () => {
    setStartDate('');
    setEndDate('');
    setAppliedStartDate('');
    setAppliedEndDate('');
  };

  // Função para formatar moeda
  const formatCurrency = (value) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
  };

  // Função de renderização da tabela principal
  const renderTable = () => {
    return (
      <div className="table-container"> {/* Adiciona o contêiner para a rolagem */}
        <table className="styled-table">
          <thead>
            <tr>
              <th>Email</th>
              <th>Total de Consultas</th>
              <th>Total de Erros</th>
              <th>Total de Sucessos</th>
              <th>Total Gasto</th>
            </tr>
          </thead>
          <tbody>
            {usages.map((usage, index) => (
              <tr key={index}>
                <td>{usage.user__email}</td>
                <td>{usage.total_queries}</td>
                <td>{usage.total_errors}</td>
                <td>{usage.total_success}</td>
                <td>{formatCurrency(usage.total_spent)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  // Função para renderizar a seção de "Consultas Utilizadas"
  const renderConsultasUtilizadas = () => {
    return (
      <div className="dashboard-box">
        <h3>Consultas Utilizadas</h3>
        <div className="dashboard-content">
          <div>Quantidade: <span>{totalQueries}</span></div>
          <div>Valor: <span>{formatCurrency(totalSpent)}</span></div>
        </div>
      </div>
    );
  };

  return (
    <div className="superuser-container">
      {/* Filtros de data */}
      <div className="filters-container">
        <div className="filter-item">
          <label>Data de Início:</label>
          <input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            className="filter-input"
          />
        </div>
        <div className="filter-item">
          <label>Data de Fim:</label>
          <input
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            className="filter-input"
          />
        </div>
        <div className="filter-buttons">
          <button onClick={applyFilters} className="filter-btn">Aplicar Filtros</button>
          <button onClick={clearFilters} className="clear-btn">Limpar Filtros</button>
        </div>
      </div>

      {/* Caixas de dashboard */}
      <div className="dashboard-container">
        {renderConsultasUtilizadas()}
        <div className="dashboard-box">
          <h3>Valor Pago</h3>
          <div className="dashboard-content">
            <div>Valor: <span>{formatCurrency(costTotal)}</span></div>
          </div>
        </div>
        <div className="dashboard-box">
          <h3>Lucro</h3>
          <div className="dashboard-content">
            {/* Aqui você pode calcular o lucro com base nos valores retornados */}
            <div>Valor: <span>{formatCurrency(totalSpent - costTotal)}</span></div>
          </div>
        </div>
      </div>

      {loading && <p>Carregando dados...</p>}
      {error && <p>Erro: {error}</p>}
      {/* Se não houver detalhes do usuário selecionado, renderiza a tabela principal */}
      {!loading && !error && usages.length > 0 && renderTable()}
    </div>
  );
}

export default SuperuserPage;
