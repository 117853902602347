import React, { useEffect, useState } from 'react';
import authFetch from '../services/authFetch'; // Vamos usar authFetch para a requisição
import '../styles/HomePage.css';  // Importa o CSS específico para a HomePage
import logo from '../assets/images/logo.jpg';  // Importa a imagem do logo
import RecargaPage from './RecargaPage';
import ConsultarPage from './ConsultarPage';
import ApisPage from './ApisPage';
import ApisJsonPage from './ApisJsonPage';
import PainelPage from './PainelPage';
import SuperuserPage from './SuperuserPage'; // Nova página para superusuários

function HomePage() {
  const [users, setUsers] = useState([]); // Estado para armazenar os dados dos usuários
  const [error, setError] = useState(null);
  const [activeContent, setActiveContent] = useState(''); // Gerencia o conteúdo a ser exibido
  const [sidebarOpen, setSidebarOpen] = useState(false); // Estado para controlar a visibilidade do menu lateral
  const [isSuperuser, setIsSuperuser] = useState(false); // Estado para verificar se é superusuário
  const [showModal, setShowModal] = useState(false); // Controla a visibilidade do modal
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    confirmedPassword: '',
    billingType: 'prepaid', // Valor padrão
  });

  // Função para buscar os usuários da API
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await authFetch('https://panel.zepesquisa.com.br/accounts/users/');
        if (!response.ok) {
          throw new Error('Erro ao buscar usuários');
        }

        const result = await response.json();
        setUsers(result); // Armazena os usuários no estado

        // Verifica se o usuário logado é superusuário e altera o estado de activeContent
        const superuser = result.find(user => user.is_superuser);
        if (superuser) {
          setIsSuperuser(true); // Define o estado isSuperuser para true
          setActiveContent('Superuser'); // Define o conteúdo ativo como 'Superuser'
        }
      } catch (err) {
        setError(err.message);
      }
    };

    fetchUsers(); // Faz a requisição quando o componente é montado
  }, []);

  // Função de logout
  const handleLogout = () => {
    localStorage.removeItem('token'); // Exemplo: remove o token de autenticação armazenado
    window.location.href = '/login'; // Redireciona para a página de login ou home
  };

  // Função para alternar a visibilidade da sidebar
  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  // Função para lidar com as alterações no formulário
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Função para enviar o formulário
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await authFetch('https://panel.zepesquisa.com.br/accounts/users/create-user/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: formData.email,
          password: formData.password,
          confirmedPassword: formData.confirmedPassword,
          billingType: formData.billingType, // Envia o tipo de cobrança como prepaid ou postpaid
        }),
      });

      if (!response.ok) {
        const errorResponse = await response.json(); // Obtem a mensagem de erro da API
        alert(errorResponse.error); // Exibe o erro em um alert
        return;
      }

      alert('Usuário criado com sucesso!');
      setShowModal(false); // Fecha o modal
    } catch (error) {
      alert('Erro ao criar usuário: ' + error.message); // Exibe o erro geral em um alert
    }
  };

  if (error) {
    return <div>Erro: {error}</div>;
  }

  return (
    <>
      <div className={`home-page-container ${sidebarOpen ? 'sidebar-open' : ''}`}>
        {/* Botão para abrir a barra lateral no mobile */}
        <button className="toggle-sidebar-btn" onClick={toggleSidebar}>
          {sidebarOpen ? 'Fechar Menu' : 'Abrir Menu'}
        </button>

        {/* Barra lateral com a imagem e botões verticais */}
        <div className={`home-sidebar ${sidebarOpen ? 'open' : ''}`}>
          <img src={logo} alt="Logo" className="sidebar-logo" />
          <button onClick={() => { setActiveContent('Consulta'); setSidebarOpen(false); }} className="home-consult">Consulta</button>
          <button onClick={() => { setActiveContent('Painel'); setSidebarOpen(false); }}>Painel</button>
          <button onClick={() => { setActiveContent('APIs HTML'); setSidebarOpen(false); }}>APIs HTML</button>
          <button onClick={() => { setActiveContent('APIs JSON'); setSidebarOpen(false); }}>APIs JSON</button>
          <button onClick={() => { setActiveContent('Recarga'); setSidebarOpen(false); }}>Recarga</button>
        </div>

        {/* Barra de informações do usuário na parte superior ao lado da barra lateral */}
        <div className="main-content">
          <div className="user-info-horizontal">
            <div className="user-list">
              {users.length > 0 ? (
                <ul>
                  {users.map(user => (
                    <li key={user.id}>
                      <strong>Email:</strong> {user.email} <br />
                      <strong>Saldo:</strong> {new Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      }).format(user.balance)} <br />
                    </li>
                  ))}
                </ul>
              ) : (
                <p>Carregando usuários...</p>
              )}
            </div>

            {/* Botões "Logout" e "Criar Login" no lado direito */}
            <div className="user-actions">
              <button className="logout-button" onClick={handleLogout}>Logout</button>

              {isSuperuser && (
                <button className="create-login-button" onClick={() => setShowModal(true)}>
                  Criar Login
                </button>
              )}
            </div>
          </div>

          {/* Conteúdo dinâmico renderizado abaixo da barra de informações */}
          <div className="dynamic-content">
            {activeContent === 'Painel' && <PainelPage />}
            {activeContent === 'APIs HTML' && <ApisPage />}
            {activeContent === 'APIs JSON' && <ApisJsonPage />}
            {activeContent === 'Recarga' && <RecargaPage />}
            {activeContent === 'Consulta' && <ConsultarPage />}

            {activeContent === 'Superuser' && <SuperuserPage />}
          </div>
        </div>
      </div>

      {/* Modal para criar login */}
      {showModal && (
        <div className="modal-overlay">
          <div className="modal">
            <h2>Criar Novo Usuário</h2>
            <form onSubmit={handleSubmit}>
              <div>
                <label>Email:</label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div>
                <label>Senha:</label>
                <input
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div>
                <label>Confirmação de Senha:</label>
                <input
                  type="password"
                  name="confirmedPassword"
                  value={formData.confirmedPassword}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div>
                <label>Tipo de Cobrança:</label>
                <select
                  name="billingType"
                  value={formData.billingType}
                  onChange={handleInputChange}
                >
                  <option value="prepaid">Pré-pago</option>
                  <option value="postpaid">Pós-pago</option>
                </select>
              </div>
              <button type="submit">Criar Usuário</button>
              <button type="button" onClick={() => setShowModal(false)}>Cancelar</button>
            </form>
          </div>
        </div>
      )}
    </>
  );
}

export default HomePage;
