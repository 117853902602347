import React, { useState, useEffect } from 'react';
import authFetch from '../services/authFetch';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import html2canvas from 'html2canvas';
import '../styles/ConsultarPage.css';

function ConsultarPage() {
  const [consultas, setConsultas] = useState([]);
  const [selectedConsulta, setSelectedConsulta] = useState('');
  const [queryParams, setQueryParams] = useState([]);
  const [paramValues, setParamValues] = useState({});
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [result, setResult] = useState(null);

  useEffect(() => {
    const fetchConsultas = async () => {
      try {
        const response = await authFetch('https://panel.zepesquisa.com.br/api/list-endpoints/');
        if (!response.ok) throw new Error('Erro ao buscar consultas');
        const data = await response.json();
        setConsultas(Object.entries(data.available_endpoints));
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };
    fetchConsultas();
  }, []);

  const handleConsultaChange = (e) => {
    const selected = e.target.value;
    setSelectedConsulta(selected);
    const consultaParams = consultas.find(([key]) => key === selected)?.[1].params || [];
    setQueryParams(consultaParams);
    setParamValues(consultaParams.reduce((acc, param) => ({ ...acc, [param]: '' }), {}));
  };

  const handleParamChange = (param, value) => {
    setParamValues((prevValues) => ({
      ...prevValues,
      [param]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    setError(null);
    setResult(null);

    // Constante para o endpoint de consulta principal
    const fetchRegisteredApisEndpoint = "https://panel.zepesquisa.com.br/api/fetch-registered-apis/";

    const selectedEndpoint = consultas.find(([key]) => key === selectedConsulta)?.[1].link;

    // Monta o payload para enviar o link e os parâmetros ao backend
    const payload = {
      link: selectedEndpoint,
      params: paramValues,  // Usa o objeto paramValues para enviar todos os parâmetros com chave-valor
    };

    try {
      const response = await authFetch(fetchRegisteredApisEndpoint, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        const errorData = await response.json();
        setError(errorData.error || 'Erro ao realizar a consulta');
      } else {
        const result = await response.text();
        setResult(result);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setSubmitting(false);
    }
  };

  const handleVoltar = () => {
    setSelectedConsulta('');
    setParamValues({});
    setError(null);
    setResult(null);
  };

  const handleSaveAsPDF = () => {
    const doc = new jsPDF({ orientation: 'p', unit: 'pt', format: 'a4' });
    doc.setFontSize(12);
    doc.setTextColor(40, 40, 40);
    doc.text('Resultado da Consulta', 30, 40);

    const resultContainer = document.querySelector('.result-container');
    resultContainer.querySelector('.save-pdf-btn').style.display = 'none';

    html2canvas(resultContainer, { scale: 1.5, useCORS: true, logging: false }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const imgWidth = 500;
      const pageHeight = doc.internal.pageSize.height;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      let position = 60;

      doc.addImage(imgData, 'PNG', 30, position, imgWidth, imgHeight);
      heightLeft -= pageHeight - 60;

      while (heightLeft > 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, 'PNG', 30, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      const pageCount = doc.internal.getNumberOfPages();
      for (let i = 1; i <= pageCount; i++) {
        doc.setPage(i);
        doc.setFontSize(8);
        doc.setTextColor(150);
        doc.text(`Página ${i} de ${pageCount}`, doc.internal.pageSize.getWidth() - 60, doc.internal.pageSize.getHeight() - 30);
      }

      resultContainer.querySelector('.save-pdf-btn').style.display = 'block';
      doc.save('resultado_consulta.pdf');
    });
  };

  return (
    <div className="consultar-container">
      <h2>Consultar</h2>
      {loading && <div>Carregando...</div>}
      {!loading && error && (
        <div className="error-container">
          <div className="error-message">Erro: {error}</div>
          <button onClick={handleVoltar} className="voltar-btn">Voltar</button>
        </div>
      )}
      {!loading && !error && (
        <>
          <form onSubmit={handleSubmit} className="recarga-form">
            <label htmlFor="consulta">Escolha a consulta:</label>
            <select
              id="consulta"
              value={selectedConsulta}
              onChange={handleConsultaChange}
              required
            >
              <option value="" disabled>Selecione uma consulta</option>
              {consultas.length > 0 ? (
                consultas.map(([key]) => (
                  <option key={key} value={key}>
                    {key.replace(/-/g, ' ')}
                  </option>
                ))
              ) : (
                <option>Nenhuma consulta disponível</option>
              )}
            </select>

            {queryParams.map((param, index) => (
              <div key={index} className="param-container">
                <label htmlFor={`param-${param}`} className="param-label"><strong>{param}:</strong></label>
                <input
                  type="text"
                  id={`param-${param}`}
                  placeholder={`Digite o valor para ${param}`}
                  value={paramValues[param] || ''}
                  onChange={(e) => handleParamChange(param, e.target.value)}
                  required
                />
              </div>
            ))}

            <button type="submit" className="generate-pix-btn" disabled={submitting}>
              {submitting ? 'Carregando...' : 'Consultar'}
            </button>
          </form>
          {result && (
            <div className="result-container">
              <h3>Resultado da Consulta</h3>
              <div dangerouslySetInnerHTML={{ __html: result }} />
              <div className="result-buttons">
                <button onClick={handleSaveAsPDF} className="save-pdf-btn">Salvar como PDF</button>
                <button onClick={handleVoltar} className="voltar-btn">Voltar</button>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default ConsultarPage;
