import React, { useState, useEffect } from 'react';
import authFetch from '../services/authFetch'; // Certifique-se de que authFetch está configurado corretamente
import '../styles/PainelPage.css';  // Importa o CSS específico para a página

function PainelPage() {
  const [error, setError] = useState(null); // Estado para armazenar erros
  const [loading, setLoading] = useState(true); // Estado para controlar o carregamento
  const [resultado, setResultado] = useState(null); // Estado para armazenar o resultado da consulta
  const [count, setCount] = useState(0); // Total de resultados
  const [pageSize] = useState(10); // Itens por página
  const [currentPage, setCurrentPage] = useState(1); // Página atual
  const [currentPageUrl, setCurrentPageUrl] = useState('https://panel.zepesquisa.com.br/api/api-usage/'); // URL da página atual
  const [nextPageUrl, setNextPageUrl] = useState(null); // URL da próxima página
  const [previousPageUrl, setPreviousPageUrl] = useState(null); // URL da página anterior

  // Estados para os filtros visuais
  const [statusFilter, setStatusFilter] = useState(''); // Filtro de status
  const [startDate, setStartDate] = useState(''); // Data de início
  const [endDate, setEndDate] = useState(''); // Data de fim

  // Estados para os filtros aplicados
  const [appliedStatusFilter, setAppliedStatusFilter] = useState('');
  const [appliedStartDate, setAppliedStartDate] = useState('');
  const [appliedEndDate, setAppliedEndDate] = useState('');

  // Fetch para os dados de consulta com filtros aplicados
  useEffect(() => {
    const fetchConsultas = async (url) => {
      console.log('Fetching data from:', url);
      setLoading(true); // Garante que o estado de carregamento seja ativado

      try {
        const response = await authFetch(url);
        if (!response.ok) {
          throw new Error('Erro ao buscar consultas');
        }

        const data = await response.json();
        setResultado(data.results); // Armazena os resultados da consulta
        setCount(data.count); // Armazena o número total de resultados
        setNextPageUrl(data.next); // Define a URL da próxima página
        setPreviousPageUrl(data.previous); // Define a URL da página anterior
        console.log('Data fetched successfully:', data);
      } catch (err) {
        console.error('Erro ao buscar consultas:', err.message);
        setError(err.message);
      } finally {
        setLoading(false); // Garante que o estado de carregamento seja desativado
      }
    };

    // Constrói a URL de filtros
    let filterUrl = `https://panel.zepesquisa.com.br/api/api-usage/?page=${currentPage}`;

    if (appliedStatusFilter) {
      filterUrl += `&status=${appliedStatusFilter}`;
    }
    if (appliedStartDate) {
      filterUrl += `&start_date=${appliedStartDate}`;
    }
    if (appliedEndDate) {
      filterUrl += `&end_date=${appliedEndDate}`;
    }

    fetchConsultas(filterUrl);
  }, [currentPageUrl, currentPage, appliedStatusFilter, appliedStartDate, appliedEndDate]);

  const formatDateTime = (dateTime) => {
    return new Date(dateTime).toLocaleString('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    });
  };

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
  };

  const handleNextPage = () => {
    if (nextPageUrl) {
      setCurrentPageUrl(nextPageUrl);
      setCurrentPage((prev) => prev + 1); // Incrementa a página atual
    }
  };

  const handlePreviousPage = () => {
    if (previousPageUrl) {
      setCurrentPageUrl(previousPageUrl);
      setCurrentPage((prev) => prev - 1); // Decrementa a página atual
    }
  };

  const applyFilters = () => {
    setCurrentPage(1); // Volta para a primeira página
    setAppliedStatusFilter(statusFilter); // Aplica o filtro de status
    setAppliedStartDate(startDate); // Aplica a data de início
    setAppliedEndDate(endDate); // Aplica a data de fim
    setCurrentPageUrl('https://panel.zepesquisa.com.br/api/api-usage/'); // Atualiza a URL base
  };

  const resetFilters = () => {
    setStatusFilter(''); // Limpa o filtro de status
    setStartDate(''); // Limpa a data de início
    setEndDate(''); // Limpa a data de fim
    setAppliedStatusFilter(''); // Limpa o filtro aplicado de status
    setAppliedStartDate(''); // Limpa a data aplicada de início
    setAppliedEndDate(''); // Limpa a data aplicada de fim
    setCurrentPage(1); // Reseta para a primeira página
    setCurrentPageUrl('https://panel.zepesquisa.com.br/api/api-usage/'); // Restaura a URL inicial para buscar todos os resultados
  };

  const downloadFile = async () => {
    try {
      let downloadUrl = 'https://panel.zepesquisa.com.br/api/api-usage/download/?';

      if (appliedStatusFilter) {
        downloadUrl += `status=${appliedStatusFilter}&`;
      }
      if (appliedStartDate) {
        downloadUrl += `start_date=${appliedStartDate}&`;
      }
      if (appliedEndDate) {
        downloadUrl += `end_date=${appliedEndDate}&`;
      }

      const response = await authFetch(downloadUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'relatorio.xlsx');
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (err) {
      console.error('Erro ao baixar o arquivo:', err.message);
      setError('Erro ao baixar o arquivo');
    }
  };

  if (loading) {
    return <div>Carregando...</div>;
  }

  if (error) {
    return <div>Erro: {error}</div>;
  }

  const totalPages = Math.ceil(count / pageSize);

  const handleFirstPage = () => {
    setCurrentPage(1);
    setCurrentPageUrl(`https://panel.zepesquisa.com.br/api/api-usage/?page=1`);
  };

  const handleLastPage = () => {
    setCurrentPage(totalPages);
    setCurrentPageUrl(`https://panel.zepesquisa.com.br/api/api-usage/?page=${totalPages}`);
  };

  return (
    <div className="painel-container">
      <h2>Painel de Consultas</h2>

      <div className="filtros-container">
        <h3>Filtrar Consultas</h3>
        <div className="filtros">
          <div className="filtro-item">
            <label>Status:</label>
            <select value={statusFilter} onChange={(e) => setStatusFilter(e.target.value)} className="filtro-select">
              <option value="">Todos</option>
              <option value="success">Sucesso</option>
              <option value="error">Erro</option>
            </select>
          </div>

          <div className="filtro-item">
            <label>Data Início:</label>
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              className="filtro-input"
            />
          </div>

          <div className="filtro-item">
            <label>Data Fim:</label>
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              className="filtro-input"
            />
          </div>

          <div className="filtro-buttons">
            <button onClick={applyFilters} className="filtro-btn">
              Aplicar Filtros
            </button>
            <button onClick={resetFilters} className="filtro-btn filtro-btn-limpar">
              Limpar Filtros
            </button>
          </div>
        </div>
      </div>

      <div className="download-button-container">
        <button onClick={downloadFile} className="download-btn">Baixar Relatório</button>
      </div>

      {resultado && (
        <div className="resultado-consulta">
          <h3>Resultado:</h3>
          <table className="consulta-tabela">
            <thead>
              <tr>
                <th>Link</th>
                <th>Data/Hora</th>
                <th>Valor</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {resultado.map((item, index) => (
                <tr key={index}>
                  <td>{item.link}</td>
                  <td>{formatDateTime(item.usage_at)}</td>
                  <td>{formatCurrency(item.sale_price)}</td>
                  <td>{item.status}</td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className="pagination-controls">
            <button onClick={handleFirstPage} disabled={currentPage === 1} className="pagination-btn">
              Primeira Página
            </button>
            <button onClick={handlePreviousPage} disabled={!previousPageUrl} className="pagination-btn">
              Página Anterior
            </button>
            <span>Página {currentPage} de {totalPages > 0 ? totalPages : 1}</span>
            <button onClick={handleNextPage} disabled={!nextPageUrl} className="pagination-btn">
              Próxima Página
            </button>
            <button onClick={handleLastPage} disabled={currentPage === totalPages} className="pagination-btn">
              Última Página
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default PainelPage;
