import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Importar useNavigate
import '../styles/Login.css'; // Importar o CSS específico para o estilo

function LoginPage() {
  const [user, setUser] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    const loginData = {
      username: user.toLowerCase(),
      password: password,
    };

    try {
      const response = await fetch('https://panel.zepesquisa.com.br/api/token/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(loginData),
      });

      if (!response.ok) {
        throw new Error('Erro na autenticação');
      }

      const data = await response.json();
      console.log(data);

      // Se o login for bem-sucedido, exibe um alerta e redireciona
      alert('Login bem-sucedido!');

      // Armazenar access token e refresh token no localStorage
      localStorage.setItem('access', data.access);  // Armazena o access token
      localStorage.setItem('refresh', data.refresh); // Armazena o refresh token
      
      // Redirecionar para a página Home
      navigate('/home');

    } catch (error) {
      // Exibe um alerta em caso de erro de login
      alert('Usuário ou senha incorretos. Tente novamente.');
    }
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <h1 className="login-title">ZéPesquisa</h1>
        <form onSubmit={handleSubmit}>
          <div className="input-group">
            <label htmlFor="user">Usuário:</label>
            <input
              type="user"
              id="user"
              value={user}
              onChange={(e) => setUser(e.target.value)}
              required
            />
          </div>
          <div className="input-group">
            <label htmlFor="password">Senha:</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="login-button">Login</button>
        </form>
      </div>
    </div>
  );
}

export default LoginPage;
