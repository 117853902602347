import React, { useEffect, useState } from 'react';
import authFetch from '../services/authFetch';
import '../styles/ApisJsonPage.css';

function APIsPage() {
  const [endpoints, setEndpoints] = useState({}); // Armazena os endpoints da API
  const [error, setError] = useState(null);
  const [userToken, setUserToken] = useState(null);

  // Função para buscar o token do primeiro usuário retornado
  useEffect(() => {
    const fetchUserToken = async () => {
      try {
        console.log("Fetching user token...");
        const response = await authFetch('https://panel.zepesquisa.com.br/accounts/users/');
        if (!response.ok) {
          throw new Error('Erro ao buscar o token do usuário');
        }

        const users = await response.json();
        console.log("Users fetched:", users);

        // Usa o token do primeiro usuário retornado na lista
        if (users.length > 0) {
          setUserToken(users[0].token);
          console.log("User token set:", users[0].token);
        } else {
          console.warn("Nenhum usuário encontrado na resposta.");
        }
      } catch (err) {
        console.error('Erro ao buscar o token do usuário:', err);
        setError(err.message);
      }
    };

    fetchUserToken();
  }, []);

  // Função para buscar os endpoints da API
  useEffect(() => {
    if (!userToken) return;

    const fetchEndpoints = async () => {
      try {
        console.log("Fetching endpoints with token:", userToken);
        const response = await authFetch('https://panel.zepesquisa.com.br/api/list-endpoints/');
        if (!response.ok) {
          throw new Error('Erro ao buscar endpoints');
        }

        const result = await response.json();
        console.log("Endpoints fetched:", result);
        setEndpoints(result.available_endpoints);
      } catch (err) {
        console.error('Erro ao buscar endpoints:', err);
        setError(err.message);
      }
    };

    fetchEndpoints();
  }, [userToken]);

  if (error) {
    return <div>Erro: {error}</div>;
  }

  const buildUrl = (endpointKey, linkData) => {
    let url = `https://panel.zepesquisa.com.br/api/${endpointKey}/?token=${userToken}&type=json`;
    linkData.params.forEach(param => {
      url += `&${param}=`;
    });
    return url;
  };

  return (
    <div className="apis-container">
      <h2>Documentação de Endpoints</h2>
      {Object.keys(endpoints).length > 0 ? (
        <ul>
          {Object.entries(endpoints).map(([key, data], index) => (
            <li key={index}>
              <p>
                <strong>Endpoint {index + 1}:</strong>{" "}
                <a href={buildUrl(key, data)} target="_blank" rel="noopener noreferrer">
                  {buildUrl(key, data)}
                </a>
              </p>
            </li>
          ))}
        </ul>
      ) : (
        <p>Carregando endpoints...</p>
      )}
    </div>
  );
}

export default APIsPage;
